<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Warehouse Code</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Warehouse
            :value="this.selectedWarehouse"
            @selected="(val) => { this.selectedWarehouse = val }"
          ></Warehouse>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
          <span>Counting Date</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <Date
            :default="false"
            @selected="(val) => { this.countingDate = val }"
          ></Date>
        </div>
      </div>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full flex items-center">
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-base">
        <Table
          :draw="draw"
          :selectedWarehouse="selectedWarehouse"
          :countingDate="countingDate"
          :status="status"
        ></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Warehouse from "../components/Warehouse.vue";
import Date from "../components/Date.vue";
import Table from "../components/Table.vue"

export default {
  components: {
    Date,
    Warehouse,
    Table,
  },
  data() {
    return {
      draw: 0,
      countingDate: null,
      status: 1,
      selectedWarehouse: null,
    };
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
  },
}
</script>
